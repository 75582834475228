<template lang="pug">
div.mb-sm-3
    table.table.table-striped(v-if="alsoKnowns && alsoKnowns.length")
        thead: tr
            th(scope="col") Id
            th(scope="col") Source Name
            th(scope="col") Pid
            th(scope="col") Url
        tbody: tr(v-for="ak in alsoKnowns")
            td
                router-link(:to="'/also-known/' + ak.id") {{ak.id}}
            td {{ ak.name_system }}
            td {{ ak.pid }}
            td {{ ak.url }}
    router-link(v-if="parent_type==='collection'"
      :to="{path: '/also-known-new', query: {parent_type: parent_type, collection_id: parent_id}}"
    ).btn.btn-success.mb-3 Add Alternative Access
    router-link(v-if="parent_type==='biobank'"
      :to="{path: '/also-known-new', query: {parent_type: parent_type, biobank_id: parent_id}}"
    ).btn.btn-success.mb-3 Add Alternative Access
    router-link(v-if="parent_type==='network'"
      :to="{path: '/also-known-new', query: {parent_type: parent_type, network_id: parent_id}}"
    ).btn.btn-success.mb-3 Add Alternative Access
</template>
<script>
import countBy from "lodash.countby";
import CollectionTypeLabels from "../labels/CollectionTypes.json";
import MaterialTypeLabels from "../labels/MaterialTypes.json";

export default {
    props: {
        "alsoKnowns" : Array,
        "parent_type": {type: String, default: ""},
        "parent_id": {type: String, default: ""}
    },
    methods: {
    },
    computed: {
    }
}
</script>
<style scoped>
.red {
  color: red;
}
</style>
