<template lang="pug">
include common.pug
form.card(@submit.prevent="saveAlsoKnown")
    h4.card-header(v-if="parent_name") #[.badge.badge-dark {{value.id}}] {{"Alternative Access for " + parent_name}}
    .card-body
        +input_if("name_system", {large: true, label: "Source Name", help: "Name of the source in which the network also exists", if: "parent_type==='network'"})(required)
        +input_if("name_system", {large: true, label: "Source Name", help: "Name of the source in which the collection also exists", if: "parent_type==='collection'"})(required)
        +input_if("name_system", {large: true, label: "Source Name", help: "Name of the source in which the biobank also exists", if: "parent_type==='biobank'"})(required)
        +input_if("pid", {large: true, help: "Persistent Identifier of the network in the other source. Either Pid or the URL must be present.", if: "parent_type==='network'"})
        +input_if("pid", {large: true, help: "Persistent Identifier of the collection in the other source. Either Pid or the URL must be present.", if: "parent_type==='collection'"})
        +input_if("pid", {large: true, help: "Persistent Identifier of the biobank in the other source. Either Pid or the URL must be present.", if: "parent_type==='biobank'"})
        +input_if("url", {large: true, help: "Link to the network in the other source", if: "parent_type==='network'"})(type="url")(required)
        +input_if("url", {large: true, help: "Link to the collection in the other source", if: "parent_type==='collection'"})(type="url")(required)
        +input_if("url", {large: true, help: "Link to the biobank in the other source", if: "parent_type==='biobank'"})(type="url")(required)
    .card-body
        button.btn.btn-primary(type="submit", :disabled="!dirty || !ok()") Save changes
        button.btn.btn-danger.ml-2(@click.prevent="deleteAlsoKnown()", v-if="!createMode") Delete Alternative Access
        span.text-danger.mx-sm-3(v-if="err") {{err.message}}
        span.text-success.mx-sm-3(v-if="saveSuccess") Changes have been saved!
</template>

<script>
import {patchAlsoKnown, postAlsoKnown, deleteAlsoKnown, getCollection, getBiobank, getNetwork} from "../api";

export default {
    props: {
        "value": Object,
        "createMode": {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dirty: false,
            err: null,
            saveSuccess: false,
            cancel: true,
            parent_type: null,
            parent_name: null,
            help3: null,
            collectionName: null,
            biobankName: null,
            networkName: null
        }
    },
    methods: {
        ok() {
          return this.value.pid || this.value.url;
        },
        goBack() {
            this.goBackImpl();
        },
        goBackImpl() {
          if (this.parent_type === "collection")
            this.$router.push("/collections/"+this.value.collection_id);
          else if (this.parent_type === "biobank")
            this.$router.push("/biobank");
          else if (this.parent_type === "network")
            this.$router.push("/networks/"+this.value.network_id);
        },
        noChanges() {
          return !this.dirty || !this.ok();
        },
        saveAlsoKnown() {
            console.log(this.value);
            if (this.parent_type === "network") {
              this.value.biobank == null;
            }
            (this.createMode ? postAlsoKnown(this.value) : patchAlsoKnown(this.value))
            .then(() => {
                this.err = null;
                this.dirty = false;
                this.$emit('changedAlsoKnown', false);
                this.saveSuccess = true;
                if (this.createMode) {
                  if (this.parent_type === 'collection')
                    this.$router.push("/collections/"+this.value.collection_id);
                  else if (this.parent_type === 'biobank')
                    this.$router.push("/biobank");
                  else if (this.parent_type === 'network')
                    this.$router.push("/network/"+this.value.network_id);
                }
            })
            .catch(err => {
                this.err = err;
                this.saveSuccess = false;
            });
        },
        deleteAlsoKnown() {
          if (confirm(`Deleting "${this.value.id}". This cannot be undone.`)) {
            deleteAlsoKnown(this.value.id)
                .then(() => {
                  this.$emit('changedAlsoKnown', false);
                  if (this.parent_type === 'collection')
                    this.$router.push("/collections/"+this.value.collection_id);
                  else if (this.parent_type === 'biobank')
                    this.$router.push("/biobank");
                  else if (this.parent_type === 'network')
                    this.$router.push("/network/"+this.value.network_id);
                })
                .catch(err => {
                  if (err.status === 409) {
                    err.message = `${this.value.email} is used as primary contact`
                  }
                  this.err = err;
                  this.saveSuccess = false;
                });
          }
        }
    },
    watch: {
        value: {
            deep: true,
            handler() {
                this.dirty = true;
                this.$emit('changedAlsoKnown', true);
                this.saveSuccess = false;
            }
        }
    },
    created() {
      this.parent_type = this.value.parent_type;
      if (this.parent_type === 'collection') {
        getCollection(this.value.collection_id).then((collection) => {
          this.collectionName = collection.name;
          this.parent_name = collection.name;
        })
      } else if (this.parent_type === 'biobank')  {
        getBiobank(this.value.biobank_id).then((biobank) => {
          this.biobankName = biobank.name;
          this.parent_name = biobank.name;
        })
      } else if (this.parent_type === 'network')  {
        getNetwork(this.value.network_id).then((network) => {
            this.networkName = network.name;
            this.parent_name = network.name;
        })
      }
    }
}
</script>
