<template lang="pug">
div
    .alert.alert-warning(v-if="err") {{err.message}}
    NetworkEditCard(v-if="network" v-model="network" @changedNetwork="onChanged")
</template>

<script>
import {getNetwork} from "../api";
import NetworkEditCard from "./NetworkEditCard.vue";
export default {
    components: {NetworkEditCard},
    data() {
        return {
            network: null,
            err: null,
            isChanged: false
        }
    },
    created() {
        this.fetchNetwork(this.$route.params.id);
    },
    beforeRouteUpdate(to, from, next){
        this.fetchNetwork(to.params.id);
        next()
    },
    methods: {
        prepareFromNetwork(network) {
          if (network.common_network_elements == null)
            network.common_network_elements = "";
          network.common_focus  = false;
          network.common_charter  = false;
          network.common_sops  = false;
          network.common_data_access_policy  = false;
          network.common_sample_access_policy  = false;
          network.common_mta  = false;
          network.common_image_access_policy  = false;
          network.common_image_mta  = false;
          network.common_representation  = false;
          network.common_url  = false;
          let res = network.common_network_elements.split(",");

          res.forEach(el => {
            network["common_"+el] = true;
          });
          return network;
        },
        fetchNetwork(id) {
            return getNetwork(id)
            .then(network => {
              this.network = this.prepareFromNetwork(network);
            })
            .catch(err => this.err = err);
        },
        onChanged(isChanged) {
          this.isChanged = isChanged;
        }
    }
}
</script>
