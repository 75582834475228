<template lang="pug">
div
    div(v-if="collection")
        CollectionEditCard.mb-3(
          v-model="collection" @changedCollection="onChanged"
        )
        CollectionTable(v-if="subcollections.length", :collections="subcollections")
        router-link(
            :to="{path: '/collection-new', query: {parent_collection: collection.id}}"
        ).btn.btn-success.mb-3 Add Subcollection
    .alert.alert-warning(v-else-if="err") {{err.message}}
</template>
<script>
import CollectionTable from "./CollectionTable.vue";
import CollectionEditCard from "./CollectionEditCard";
import {getCollection, getCollectionsByParent, getAlsoKnownByCollection} from "../api";

export default {
    components: {CollectionTable, CollectionEditCard},
    data() {
        return {
            collection: null,
            subcollections: [],
            isChanged: false,
            err: null,
            sop_values: [
            "sample_processing_sop",
            "sample_transport_sop",
            "sample_storage_sop",
            "data_processing_sop",
            "data_transport_sop",
            "data_storage_sop"
            ],
            access_values: ["data","samples","images"]
        }
    },
    created(){
      console.log("created "+this.$route.params.id)
        this.fetchData(this.$route.params.id);
        // console.log("created top "+this.collection.id)
    },
    beforeRouteUpdate(to, from, next) {
        this.fetchData(to.params.id);
        next();
    },
    methods: {
        fetchData(collectionId) {
            return Promise.all([
                getCollection(collectionId)
                .then((collection) => {
                  this.initAttributeFromString("sop", collection, this.sop_values);
                  this.initAttributeFromString("access_fee", collection, this.access_values);
                  this.initAttributeFromString("access_joint_project", collection, this.access_values);
                  this.collection = collection;
                }),
                getCollectionsByParent(collectionId)
                .then(subcollections => this.subcollections = subcollections)
            ]).then(() => {
            })
            .catch(err => this.err = err);
        },
      onChanged(isChanged) {
        this.isChanged = isChanged;
      },
      initAttributeFromString(name, collection, allValues) {

        collection[name+"_New"] = {}

        allValues.forEach((val)=>{
          collection[name+"_New"][val] = false;
        });

        if (collection[name] == null) return;

        let valArray = collection[name].split(",");

        valArray.forEach((val) => {
          collection[name+"_New"][val] = true;
        });

      },

    }
}
</script>
