<template lang="pug">
    select.form-control(@change="change")
        option(v-if="network.id != null" v-for="network in filteredNetworksWithEmptyOption", :value="network.id", :selected="network.id === value")
            | {{getNetworkName(network)}}
        option(:selected="value == null" :value="null")
            | --- No parent network ---
</template>

<script>
import {getNetworks} from "../api";
import {filteredNetworksWithEmptyOption} from "./common";
import * as net from "net";

export default {
    props: {
      "id": String,
      "value": String
    },
    data() {
        return {
            networks: []
        };
    },
    created() {
        getNetworks()
        .then(networks => {

            this.networks = networks;
            if (this.value == null && networks.length > 0)
                this.$emit("input", null);
        });
    },
    methods: {
        getNetworkName(network) {
          return network.acronym != null && network.acronym !== "" ? network.acronym : network.name
        },
        change(e) {
            this.$emit("input", e.target.value);
        }
    },
    computed: {filteredNetworksWithEmptyOption}
}
</script>
