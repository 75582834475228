<template lang="pug">
include common.pug
form.card(@submit.prevent="saveCollection")
    +card-header
    .card-body
        .form-row
            .col: +input("name", {help: "≤ 60 characters"})#name(required)
            .col: +input("acronym", {help: "≤ 20 characters"})
        .form-row
            .col: +input("size", {help: "current number of unique sample IDs"})(type="number", min="0")
            .col: +input("number_of_donors")(type="number", min="0")
        +textarea("description", {help: "Description of the collection in English"})(rows=3,required)
        //-+input("bioresource_reference", {help: "Reference to be cited when the collection is used for research", large: true})(maxlength=255)
        .form-group.mb-2
            h6 Collection Types
                +required
            MultipleSelect(:labels="CollectionTypes", v-model="value.type", :required="true")
            small.text-info(v-if="!value.type.includes('IMAGE') || !value.type.includes('SAMPLE')")
                | Select "Sample collection" and/or "Image collection" to show fields specific to those collection types.
        .form-group.mb-2
            h6 Data Categories
                +required
            MultipleSelect(:labels="DataTypes", v-model="value.data_categories", :required="true")
            //-+input("id_card", {help: "URL of the RD-Connect ID-Card record (Rare Disease collections only)", large: true})(
            //-type="url", placeholder="https://catalogue.rd-connect.eu/"
            //)
    .card-body(v-if="value.type.includes('IMAGE')")
        h5 Imaging Data
        .form-group.mb-2
            h6 Body Parts Examined
            MultipleSelect(:labels="BodyParts", v-model="value.body_part_examined")
        .form-group.mb-2
            h6 Imaging Modality
            MultipleSelect(:labels="ImagingModality", v-model="value.imaging_modality")
            small Imaging modalities used for generating the images
        .form-group
            h6 Image Dataset Type
            MultipleSelect(:labels="ImageDataTypes", v-model="value.image_dataset_type")
    .card-body(v-if="value.type.includes('SAMPLE')")
        h5 Sample Data
        .form-group.mb-2
            h6 Material Types
            MultipleSelect(:labels="MaterialTypes", v-model="value.materials")
        .form-group.mb-2
            h6 Storage Temperatures
            MultipleSelect(:labels="TemperatureTypes", v-model="value.storage_temperatures")
    .card-body
        h5 Contact Information
        .form-inline.mb-2
            label Collection Head
            //-            +headTitleAndName
                +required
            person-select-with-empty-option.mx-3(v-model="value.head")
            small Natural person in charge of the collection
        //-+input("head_role", {help: "typically, principal investigator or director"})
        .form-inline.mb-2
            label Primary External Contact
                +required
            person-select.mx-sm-3(v-model="value.contact")
        .form-inline.mb-2
            label Networks
            NetworksSelect.mx-3(v-model="value.network")
        .form-inline
        .form-inline.mb-2
            +input("location", { label: "City", help: "The city where the collection resides"})
        //-.form-inline.mb-2
            label Primary Site Location
            +latLong
    .card-body
        h5 Donor Data
        .form-group.mb-2
            h6 Sex
            MultipleSelect(:labels="SexTypes", v-model="value.sex")
            small The sex of the individuals whose samples are part of the collection
        .input-group.mb-2
            .input-group-prepend: span.input-group-text Age
            input.form-control(type="number", min=0, :max="value.age_high", placeholder=18, v-model="value.age_low")
            .input-group-prepend.input-group-append: span.input-group-text -
            input.form-control(type="number", :min="value.age_low || 0", placeholder=99, v-model="value.age_high")
            select.form-control.mx-3(v-model="value.age_unit")
                option(value="")
                option(value="YEAR") Years
                option(value="MONTH") Months
                option(value="WEEK") Weeks
                option(value="DAY") Days
        .form-group
            h6 Diagnosis Available
            DiseaseSelect(v-if="value" v-model="value.diagnosis_available")
            small Type to search for ICD10 codes
    .card-body
        h5 Access Policy
        +checkbox("collaboration_commercial", "Material in the collection is available for use in a commercial context")
        +checkbox("collaboration_non_for_profit", "Material in the collection is available for use in a not-for-profit context")
        +accessPolicy2("samples", "samples")(v-if="value.type.includes('SAMPLE')")
        +accessPolicy2("data", "data")
        +accessPolicy2("images", "images")(v-if="value.type.includes('IMAGE')")
        +textarea("access_description", {help: `Conditions for access in English`})
        +input("access_uri", {
            help: `URL to a detailed description of the access conditions`,
            large: true}
        )(type=`url`)
    .card-body
        h5 Standard operating procedure (SOP)
        +checkbox("sop_New['sample_processing_sop']", "…for processing samples")
        +checkbox("sop_New['sample_transport_sop']", "…for shipping samples")
        +checkbox("sop_New['sample_storage_sop']", "…for storing samples")
        +checkbox("sop_New['data_processing_sop']", "…for processing data in the collection")
        +checkbox("sop_New['data_transport_sop']", "…for transporting data")
        +checkbox("sop_New['data_storage_sop']", "…for storing data")
    .card-body(v-if="!createMode")
        h5 Fact Data
        router-link(v-if="!createMode"
          :to="{path: '/facts/'+value.id}"
        ).btn.btn-success.mb-3 Manage Aggregated Fact Data
    .card-body(v-if="!createMode")
      h5 Alternative Access
      AlsoKnownTable(:alsoKnowns="alsoknown", :parent_type="'collection'", :parent_id="value.id")
    .card-body
        button.btn.btn-primary(type="submit", :disabled="noChanges()") Save changes
        //-button.btn.btn-primary.ml-2(@click.prevent="goBackToCollections()" v-if="!hasParent()") Go to collection list
        //-button.btn.btn-primary.ml-2(@click.prevent="goBackToParent()" v-if="hasParent()") Go to parent collection
        button.btn.btn-danger.ml-2(@click.prevent="deleteCollection()", v-if="!createMode") Delete Collection
        span.text-danger.mx-sm-3(v-if="err") {{err.message}}
        span.text-success.mx-sm-3(v-if="saveSuccess") Changes have been saved!
</template>

<script>
import mapValues from "lodash.mapvalues";
import capitalize from "lodash.capitalize";
import PersonSelect from "./PersonSelect.vue";
import NetworksSelect from "./NetworksSelect.vue";
import DiseaseSelect from "./DiseaseSelect.vue";
import MultipleSelect from "./MultipleSelect.vue";
import {deleteCollection, getAlsoKnownByCollection, patchCollection, postCollection} from "../api";

import CollectionTypes from "../labels/CollectionTypes.json";
import DataTypes from "../labels/DataTypes.json";
import MaterialTypes from "../labels/MaterialTypes.json";
import TemperatureTypes from "../labels/TemperatureTypes.json";
import SexTypes from "../labels/SexTypes.json";
import BodyParts from "../labels/BodyParts.json";
import ImageDataTypes from "../labels/ImageDataTypes.json";
import ImagingModality from "../labels/ImagingModality.json";
import PersonSelectWithEmptyOption from "./PersonSelectWithEmptyOption";
import AlsoKnownTable from "./AlsoKnownTable";
import readXlsxFile, {Integer} from 'read-excel-file'

const _ = require('lodash');

export default {
  components: {AlsoKnownTable, PersonSelect, PersonSelectWithEmptyOption, NetworksSelect, DiseaseSelect, MultipleSelect},
  props: {
    "createMode": {
      type: Boolean,
      default: false
    },
    "value": Object,
  },
  data() {
    return {
      dirty: false,
      collectionFileChanged: false,
      donorFileChanged: false,
      saveSuccess: false,
      alsoknown: [],
      err: null,
      CollectionTypes, DataTypes, MaterialTypes,
      TemperatureTypes, SexTypes, ImageDataTypes,
      BodyParts: mapValues(BodyParts, capitalize),
      ImagingModality,
  }
  },
  methods: {
    noChanges() {
      // return !this.dirty;
      return !this.dirty && !this.anyChanged();
    },
    anyChanged() {
      return this.oneChanged(this.sop, this.origSop) || this.oneChanged(this.access_fee, this.origAccessFee)
          || this.oneChanged(this.access_joint_project, this.origAccessJointProject) || this.collectionFileChanged
          || this.donorFileChanged;
    },
    oneChanged(valobj, orig) {
      for (const myval in valobj) {
        if (orig[myval] !== valobj[myval]) return true;
      }
      return false;
    },
    goBackToParent() {
        this.$router.push("/collections/" + (this.value.parent_collection || ""));
    },
    hasParent() {
      return this.value.parent_collection != null;
    },
    goBackToCollections() {
        this.$router.push("/collections");
    },
    goBack() {
      if (this.hasParent()) this.goBackToParent()
      else this.goBackToCollections();
    },
    formatList(valobj) {
      let vals = [];
      for (const myval in valobj) {
        if (valobj[myval]) vals.push(myval);
      }
      return vals.join(",");
    },
    saveCollection(){

      this.value.sop = this.formatList(this.value['sop_New']);
      this.value.access_fee = this.formatList(this.value['access_fee_New']);
      this.value.access_joint_project = this.formatList(this.value['access_joint_project_New']);

      (this.createMode ? postCollection(this.value) : patchCollection(this.value))
          .then(() => {
            this.saveSuccess = true;
            this.collectionFileChanged = false;
            this.donorFileChanged = false;
            this.value.collectionFacts = [];
            this.value.donorFacts = [];
            this.$refs.factCollectionFile.value = null;
            this.$refs.factDonorFile.value = null;
            this.dirty = false;

            this.$emit('changedCollection', false);

            this.err = null;
            if (this.createMode)
              this.goBack();
          })
          .catch(err => {
            this.err = err;
            this.saveSuccess = false;
          });
    },
    resetOne(valobj, orig) {
      for (const myval in valobj) {
        orig[myval] = valobj[myval];
      }
    },
    deleteCollection() {
      if (!confirm(`Deleting "${this.value.id}" and all its subcollections. This cannot be undone.`))
        return;
      deleteCollection(this.value.id)
          .then(() => {
            this.$emit('changedCollection', false);
            this.goBack()
          })
          .catch(err => {
            this.err = err;
            this.saveSuccess = false;
          });
    },
    initOne(valobj, orig, strVal) {
      for (const myval1 in valobj) {
        orig[myval1] = valobj[myval1];
      }
      if (strVal == null) return;
      let valArray = strVal.split(",");
      valArray.forEach((myval) => {
        valobj[myval] = orig[myval] = true;
      });

    },
  },
  watch: {
    value: {
      deep: true,
      handler(oldValue, newValue) {
        if (oldValue.id === newValue.id) {
          if (newValue.id == null) {
            this.dirty = false;
            this.$emit('changedCollection', false);

          } else {
            this.saveSuccess = false;
            this.dirty = true;
            this.$emit('changedCollection', true);
          }
        } else {
          this.dirty = false;
          this.$emit('changedCollection', false);
        }
      }
    },
  },
  created() {

    getAlsoKnownByCollection(this.value.id)
        .then(alsoKnownList => this.alsoknown = alsoKnownList)

  }
}
</script>

<style>
#name {width: 20em;}
</style>
