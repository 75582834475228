import {apiBaseUrlDevelopment, apiBaseUrlProduction} from "../config.json";

//see: https://parceljs.org/features/production/
const apiBaseUrl = process.env.NODE_ENV === "development" ? apiBaseUrlDevelopment : apiBaseUrlProduction;

function request(path, options){
    options = options || {};
    options.credentials = "include";
    options.headers = options.headers || {};
    if (options.body){
        options.headers["Content-Type"] = "application/json";
    }

    return fetch(apiBaseUrl + path, options).then(res => {
        return res.json().then(json => {
            if (!res.ok){
                json.status = res.status;
                return Promise.reject(json);
            }
            return json;
        });
    });
}

const login = (email, password) => request("/login", {
    method: "POST",
    body: JSON.stringify({email, password})
});
const changePassword = (oldPassword, newPassword) => request("/password", {
    method: "PATCH",
    body: JSON.stringify({oldPassword, newPassword})
});
const logout = () => request("/logout");

const getBiobank = id => request("/biobanks/" + id);
const patchBiobank = biobank => request("/biobanks/" + biobank.id, {
    method: "PATCH",
    body: JSON.stringify(biobank)
});

const getCollection = id => request("/collections/" + id);
const getCollectionsByBiobank = biobankId => request(
    "/collections?biobank=" + encodeURIComponent(biobankId)
);
const getCollectionsByParent = parentCollectionId => request(
    "/collections?parent_collection=" + encodeURIComponent(parentCollectionId)
);
const getAlsoKnownByBiobank = biobankId => request(
    "/also-known?biobank_id=" + encodeURIComponent(biobankId)
);
const getAlsoKnownByCollection = collectionId => request(
    "/also-known?collection_id=" + encodeURIComponent(collectionId)
);
const getAlsoKnownByNetwork = networkId => request(
    "/also-known?network_id=" + encodeURIComponent(networkId)
);
const postCollection = collection => request("/collections/", {
    method: "POST",
    body: JSON.stringify(collection)
});
const patchCollection = collection => request("/collections/" + collection.id, {
    method: "PATCH",
    body: JSON.stringify(collection)
});
const deleteCollection = id => request("/collections/" + id, {method: "DELETE"});

const getFacts = collectionId => request("/facts?CollectionId=" + encodeURIComponent(collectionId));
const deleteFacts = collectionId => request("/delete-facts?CollectionId=" + encodeURIComponent(collectionId));

const getPeople = () => request("/people/");
const getPerson = id => request("/people/" + id);
const postPerson = person => request("/people/", {
    method: "POST",
    body: JSON.stringify(person)
});
const patchPerson = person => request("/people/" + person.id, {
    method: "PATCH",
    body: JSON.stringify(person)
});
const deletePerson = id => request("/people/" + id, {method: "DELETE"});

const getAlsoKnown = id => request("/also-known/" + id);
const postAlsoKnown = alsoKnown => request("/also-known/", {
    method: "POST",
    body: JSON.stringify(alsoKnown)
});
const patchAlsoKnown = alsoKnown => request("/also-known/" + alsoKnown.id, {
    method: "PATCH",
    body: JSON.stringify(alsoKnown)
});
const deleteAlsoKnown = id => request("/also-known/" + id, {method: "DELETE"});


const getNetworks = () => request("/networks/");
const getNetwork = id => request("/networks/" + id);
const postNetwork = network => request("/networks/", {
    method: "POST",
    body: JSON.stringify(network)
});
const patchNetwork = network => request("/networks/" + network.id, {
    method: "PATCH",
    body: JSON.stringify(network)
});
const deleteNetwork = id => request("/networks/" + id, {method: "DELETE"});

const searchDiseaseTypes = query => request("/disease-types/?q=" + encodeURIComponent(query));

export {
    login, logout, changePassword, getBiobank, patchBiobank, postPerson,
    getPeople, getPerson, patchPerson, deletePerson, postCollection,
    getCollection, getCollectionsByBiobank, getCollectionsByParent,
    patchCollection, deleteCollection, searchDiseaseTypes, getNetworks,
    postNetwork, getNetwork, patchNetwork, deleteNetwork,
    getAlsoKnownByBiobank, getAlsoKnownByCollection, getAlsoKnownByNetwork,
    getAlsoKnown, postAlsoKnown, patchAlsoKnown, deleteAlsoKnown, getFacts, deleteFacts
};
