<template lang="pug">
div
    nav(v-if="user").navbar.navbar-light.bg-light
        ul.nav.nav-pills
            li.nav-item: router-link.nav-link(to="/biobank") Biobank
            li.nav-item: router-link.nav-link(to="/collections") Collections
            li.nav-item: router-link.nav-link(to="/networks") Networks
            li.nav-item: router-link.nav-link(to="/people") People
        .nav-item
            router-link.mr-sm-3(to="/user") {{user}}
            button.btn.btn-danger(@click="logout") Sign out
    .container.mt-sm-3: router-view
</template>

<script>
import {logout} from "./api";

export default {
    data() {
        return {
            user: localStorage.getItem("user")
        }
    },
    methods: {
        logout() {
            localStorage.clear();
            document.cookie = "connect.sid=;";
            logout()
            .then(() => {
                this.user = "";
                this.$router.push("/login");
            });
        }
    },
    watch: {
        $route() {
            this.user = localStorage.getItem("user");
        }
    }
}
</script>
