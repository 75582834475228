<template lang="pug">
.alert.alert-warning(v-if="err") {{err.message}}
div.mb-sm-3(v-else)
    table.table.table-striped
        thead: tr
            th(scope="col") Network
            th(scope="col") Acronym
            th(scope="") Description
            th(scope="") Cohort
        tbody: tr(v-for="network in networks")
            td
                router-link(:to="'/network/' + network.id") {{network.name}}
            td {{ network.acronym }}
            td {{ network.description }}
            td(style="text-align:center")
                i.pi.pi-check(v-if="network.cohort_flag")
    router-link.btn.btn-success.my-3(to="/network-new") Add Network
</template>

<script>
import {getNetworks} from "../api";

export default {
    data() {
        return {
            err: null,
            networks: []
        }
    },
    created() {
        getNetworks()
        .then(networks => this.networks = networks)
        .catch(err => this.err = err);
    },
}
</script>
