<template lang="pug">
    NetworkEditCard(v-model="network", :createMode="true" @changedNetwork="onChanged")
</template>

<script>
import NetworkEditCard from "./NetworkEditCard.vue";
import {country, badCharsRegex} from "../../config.json";

const badChars = new RegExp(badCharsRegex, "g");

export default {
    components: {NetworkEditCard},
    data() {
        return {
            network: {country},
            isChanged: false
        };
    },
    watch: {
      "network.id1"(id) {
        console.log("watch")
        let prefix = "bbmri-eric:networkID:";
        this.network.id = prefix + id.replace(badChars, "");
      }
    },
    methods: {
      onChanged(isChanged) {
        this.isChanged = isChanged;
      },
      created() {
        this.network.id = "";
        // console.log("created")
      },

    }
}
</script>
