<template lang="pug">
include common.pug
form.card(@submit.prevent="saveNetwork")
    +card-header
    .card-body
        .form-row
            .col: +input("name", {help: "≤ 60 characters"})#name(required)
            .col: +input("acronym", {help: "≤ 20 characters"})
        .form-row(v-if="createMode")
            .col: +inputWithNetworkIDPrefix("id1", {large:true, label:'Id'})#id(required)
        +textarea("description", {help: "Description of the network in English"})(rows=3,required)
        +input("url", {label: "Website URL", large: true})(type="url")
        .form-row
            .col: +input("juridical_person", {help: "Name of the legal entity of the network", large: true})
            .col: +input("location", {help: "The city where the network is located", large: true})
        h5 Contact Information
        .form-inline.mb-2
            label Network contact
            person-select-with-empty-option.mx-3(v-model="value.contact")
            small Natural person in charge of the network
        h5 Common Network Elements
        +checkbox("common_focus", "All the biobanks/collections in the network share the same focus with which the samples are collected (e.g., disease specific). Further details of the focus should be provided in the Description attribute")
        +checkbox("common_charter", "All the biobanks/collections in the network have to have a network charter signed")
        +checkbox("common_sops", "All the biobanks/collections in the network share the same SOPs")
        +checkbox("common_data_access_policy", "All the biobanks/collections in the network share the same data access policy")
        +checkbox("common_sample_access_policy", "All the biobanks/collections in the network share the same sample access policy")
        +checkbox("common_mta", "All the biobanks/collections in the network share the same MTA")
        +checkbox("common_image_access_policy", "All the biobanks/collections in the network share the same image access policy")
        +checkbox("common_image_mta", "All the biobanks/collections in the network share the same image MTA")
        +checkbox("common_representation", "All the biobanks/collections in the network are represented using the network only")
        +checkbox("common_url", "All the biobanks/collections in the network share the same web presentation on the common URL")
        h5 Parent network Information
        .form-inline.mb-2
            label Parent network
            network-select-with-empty-option.mx-3(:id="value.id" v-model="value.parent_network")
            small The parent in the network hierarchy
        h5 Cohort Information
        +checkbox("cohort_flag", "This network is flagged as a cohort network")
    .card-body(v-if="!createMode")
        h5 Alternative Access
        AlsoKnownTable(:alsoKnowns="alsoknown", :parent_type="'network'", :parent_id="value.id")
        h5 Withdrawal Information
        +checkbox("withdrawn", "This network is withdrawn from the Directory")
    .card-body
        button.btn.btn-primary(type="submit", :disabled="!dirty") Save changes
        button.btn.btn-danger.ml-2(@click.prevent="deleteNetwork()", v-if="!createMode") Delete network
        span.text-danger.mx-sm-3(v-if="err") {{err.message}}
        span.text-success.mx-sm-3(v-if="saveSuccess") Changes have been saved!
</template>

<script>
import {patchNetwork, postNetwork, deleteNetwork, getAlsoKnownByCollection, getAlsoKnownByNetwork} from "../api";
import PersonSelectWithEmptyOption from "./PersonSelectWithEmptyOption.vue";
import AlsoKnownTable from "./AlsoKnownTable";
import NetworkSelectWithEmptyOption from "./NetworkSelectWithEmptyOption.vue";

export default {
  components: {NetworkSelectWithEmptyOption, AlsoKnownTable, PersonSelectWithEmptyOption},
    props: {
        "value": Object,
        "createMode": {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dirty: false,
            err: null,
            alsoknown: [],
            saveSuccess: false,
            cancel: true
        }
    },
    methods: {
        noChanges() {
            return !this.dirty;
        },
        goBack() {
            this.$router.push("/networks");
        },
        prepareNetwork() {
          let res = [];
          for (const property in this.value) {
            if (property.substring(0,7) === "common_" && property !== "common_network_elements") {
              if (this.value[property] === true) {
                let val = property.substring(7,property.length);
                if (val != null && val !== "") res.push(val);
              }
            }
          }
          console.log(res);
          this.value.common_network_elements = res.join(",");
        },
        saveNetwork() {
            this.prepareNetwork();
            (this.createMode ? postNetwork(this.value) : patchNetwork(this.value))
            .then(() => {
                this.err = null;
                this.dirty = false;
                this.$emit('changedNetwork', false);
                this.saveSuccess = true;
                if (this.createMode)
                    this.$router.push("/networks");
            })
            .catch(err => {
                this.err = err;
                this.saveSuccess = false;
            });
        },
        deleteNetwork() {
          if (confirm(`Deleting "${this.value.id}". This cannot be undone.`)) {
            deleteNetwork(this.value.id)
                .then(() => {
                  this.$emit('changedNetwork', false);
                  this.$router.push("/networks");
                })
                .catch(err => {
                  if (err.status === 409) {
                    err.message = `${this.value.email} is used as primary contact`
                  }
                  this.err = err;
                  this.saveSuccess = false;
                });
          }
        }
    },
    watch: {
        value: {
            deep: true,
            handler() {
                this.dirty = true;
                this.saveSuccess = false;
                this.$emit('changedNetwork', true);
            }
        }
    },
    created() {
      getAlsoKnownByNetwork(this.value.id)
          .then(alsoKnownList => this.alsoknown = alsoKnownList)

    }
}
</script>
